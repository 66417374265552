<template>
	<div class="py-2">
		<div class="pb-1 px-4 header">
			<h1 class="crud-title">
				Color Customization for the Selected Theme
			</h1>
		</div>
		<v-radio-group v-model="type" row class="px-6">
			<v-radio
				v-for="type in colorOptions"
				:key="type"
				:label="type"
				:value="type"
			></v-radio>
		</v-radio-group>
		<div class="pa-4" v-if="type === 'Suggested'">
			<ColorPallete></ColorPallete>
		</div>
		<div v-else class="px-8">
			<ColorCustomization
				:colors="colors"
				@on-colors-update="updateColors($event)"
			></ColorCustomization>
		</div>
	</div>
</template>

<script>
import ColorPallete from './components/ColorPallete'
import ColorCustomization from './components/ColorCustomization'
export default {
	components: {
		ColorPallete,
		ColorCustomization,
	},
	data() {
		return {
			type: 'Suggested',
			colorOptions: ['Suggested', 'Customized'],
			colors: {},
		}
	},
	created() {
		this.getColors()
	},
	methods: {
		getColors() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/project/${projectId}/colors`,
			}).then((res) => {
				this.colors = res.data
			})
		},
		updateColors(emittedObj) {
			this.colors = emittedObj
			this.saveColors()
		},
		saveColors() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'put',
				url: `/project/${projectId}/colors`,
				data: this.colors,
			}).then((res) => {
				console.log(res)
			})
		},
	},
}
</script>
<style scoped>
.header {
	border-bottom: 1px solid #eee;
}
.heading {
	font-size: 1.3rem;
}
.color-title {
	color: black !important;
}
.sections {
	color: var(--blue);
}
</style>
