<template>
	<v-row class="mx-0">
		<v-col cols="12" class="pa-0 d-flex flex-row">
			<v-col cols="4" class="pa-0">
				<ol class="pl-4">
					<li class="sections mb-5">
						<h1 class="text-h6 mb-3">Website</h1>
						<v-row class="mx-0">
							<v-col
								cols="12"
								class="
									pa-0
									d-flex
									flex-row
									mb-3
									align-center
									justify-space-between
								"
							>
								<p class="mr-5 color-title">Primary</p>
								<div
									class="
										customized-color-box customized-primary
									"
									@click="
										;(customizePrimaryColor = true),
											(ShowColorPallete = true)
									"
									:style="{
										backgroundColor: customizedPrimaryColor,
									}"
								></div>
							</v-col>
							<v-col
								cols="12"
								class="
									pa-0
									d-flex
									flex-row
									mb-3
									align-center
									justify-space-between
								"
							>
								<p class="mr-5 color-title">Secondary</p>
								<div
									class="customized-color-box"
									@click="
										;(customizeSecondaryColor = true),
											(ShowColorPallete = true)
									"
									:style="{
										backgroundColor:
											customizedSecondaryColor,
									}"
								></div>
							</v-col>
							<v-col
								cols="12"
								class="
									pa-0
									d-flex
									flex-row
									align-center
									mb-3
									justify-space-between
								"
							>
								<p class="mr-5 color-title">Background</p>
								<div
									class="customized-color-box"
									@click="
										;(customizeBackgroundColor = true),
											(ShowColorPallete = true)
									"
									:style="{
										backgroundColor:
											customizedBackgroundColor,
									}"
								></div>
							</v-col>
							<v-col
								cols="12"
								class="
									pa-0
									d-flex
									flex-row
									align-center
									mb-3
									justify-space-between
								"
							>
								<p class="mr-5 color-title">Primary Text</p>
								<div
									class="customized-color-box"
									@click="
										;(customizePrimaryTextColor = true),
											(ShowColorPallete = true)
									"
									:style="{
										backgroundColor:
											customizedPrimaryTextColor,
									}"
								></div>
							</v-col>
							<v-col
								cols="12"
								class="
									pa-0
									d-flex
									flex-row
									align-center
									mb-3
									justify-space-between
								"
							>
								<p class="mr-5 color-title">Secondary Text</p>
								<div
									class="customized-color-box"
									@click="
										;(customizeSecondaryTextColor = true),
											(ShowColorPallete = true)
									"
									:style="{
										backgroundColor:
											customizedSecondaryTextColor,
									}"
								></div>
							</v-col>
						</v-row>
					</li>
					<li class="sections mb-5">
						<h1 class="text-h6">Navbar</h1>
						<v-row class="mx-0">
							<v-col
								cols="12"
								class="
									pa-0
									d-flex
									flex-row
									mb-3
									align-center
									justify-space-between
								"
							>
								<p class="mr-5 color-title">Background</p>
								<div
									class="
										customized-color-box customized-primary
									"
									@click="
										;(customizeNavbarBackgroundColor = true),
											(ShowColorPallete = true)
									"
									:style="{
										backgroundColor: navbar.background,
									}"
								></div>
							</v-col>
							<v-col
								cols="12"
								class="
									pa-0
									d-flex
									flex-row
									mb-3
									align-center
									justify-space-between
								"
							>
								<p class="mr-5 color-title">Text</p>
								<div
									class="customized-color-box"
									@click="
										;(customizeNavbarTextColor = true),
											(ShowColorPallete = true)
									"
									:style="{
										backgroundColor: navbar.text,
									}"
								></div>
							</v-col>
						</v-row>
					</li>
					<li class="sections mb-5">
						<h1 class="text-h6">Footer</h1>
						<v-row class="mx-0">
							<v-col
								cols="12"
								class="
									pa-0
									d-flex
									flex-row
									mb-3
									align-center
									justify-space-between
								"
							>
								<p class="mr-5 color-title">Background</p>
								<div
									class="
										customized-color-box customized-primary
									"
									@click="
										;(customizeFooterBackgroundColor = true),
											(ShowColorPallete = true)
									"
									:style="{
										backgroundColor: footer.background,
									}"
								></div>
							</v-col>
							<v-col
								cols="12"
								class="
									pa-0
									d-flex
									flex-row
									mb-3
									align-center
									justify-space-between
								"
							>
								<p class="mr-5 color-title">Text</p>
								<div
									class="customized-color-box"
									@click="
										;(customizeFooterTextColor = true),
											(ShowColorPallete = true)
									"
									:style="{
										backgroundColor: footer.text,
									}"
								></div>
							</v-col>
						</v-row>
					</li>
				</ol>
			</v-col>
			<v-col
				cols="8"
				class="pa-0 d-flex justify-end"
				v-if="ShowColorPallete"
			>
				<v-color-picker
					class="ma-2"
					show-swatches
					type="hex"
					v-model="color"
				></v-color-picker>
			</v-col>
		</v-col>
		<div class="d-flex flex-row">
			<v-btn
				@click="saveColors()"
				depressed
				class="save"
				:loading="loading"
				>save</v-btn
			>
		</div>
	</v-row>
</template>

<script>
export default {
	props: {
		colors: {
			type: Object,
		},
	},
	created() {
		if (Object.keys(this.colors).length > 0) {
			this.customizedPrimaryColor = this.colors.primary
			this.customizedSecondaryColor = this.colors.secondary
			this.customizedBackgroundColor = this.colors.background
			this.customizedPrimaryTextColor = this.colors.primaryText
			this.customizedSecondaryTextColor = this.colors.secondaryText
			this.navbar.background = this.colors.navbar.background
			this.navbar.text = this.colors.navbar.text
			this.footer.background = this.colors.footer.background
			this.footer.text = this.colors.footer.text
		}
	},
	data() {
		return {
			color: '',
			ShowColorPallete: false,
			customizePrimaryColor: false,
			customizeSecondaryColor: false,
			customizeBackgroundColor: false,
			customizePrimaryTextColor: false,
			customizeSecondaryTextColor: false,
			customizedPrimaryColor: '',
			customizedSecondaryColor: '',
			customizedBackgroundColor: '',
			customizedPrimaryTextColor: '',
			customizedSecondaryTextColor: '',
			customizeNavbarBackgroundColor: false,
			customizeNavbarTextColor: false,
			customizeFooterBackgroundColor: false,
			customizeFooterTextColor: false,
			navbar: {
				background: '',
				text: '',
			},
			footer: {
				background: '',
				text: '',
			},
			loading: false,
		}
	},
	watch: {
		color() {
			if (this.customizePrimaryColor) {
				return (this.customizedPrimaryColor = this.color)
			}
			if (this.customizeSecondaryColor) {
				return (this.customizedSecondaryColor = this.color)
			}
			if (this.customizeBackgroundColor) {
				return (this.customizedBackgroundColor = this.color)
			}
			if (this.customizePrimaryTextColor) {
				return (this.customizedPrimaryTextColor = this.color)
			}
			if (this.customizeSecondaryTextColor) {
				return (this.customizedSecondaryTextColor = this.color)
			}
			if (this.customizeNavbarBackgroundColor) {
				return (this.navbar.background = this.color)
			}
			if (this.customizeNavbarTextColor) {
				return (this.navbar.text = this.color)
			}
			if (this.customizeFooterBackgroundColor) {
				return (this.footer.background = this.color)
			}
			if (this.customizeFooterTextColor) {
				return (this.footer.text = this.color)
			}
		},
		customizePrimaryColor() {
			return (
				(this.customizeSecondaryColor = false),
				(this.customizeBackgroundColor = false),
				(this.customizePrimaryTextColor = false),
				(this.customizeSecondaryTextColor = false),
				(this.customizeFooterBackgroundColor = false),
				(this.customizeFooterTextColor = false),
				(this.customizeNavbarTextColor = false),
				(this.customizeNavbarBackgroundColor = false)
			)
		},
		customizeSecondaryColor() {
			return (
				(this.customizePrimaryColor = false),
				(this.customizeBackgroundColor = false),
				(this.customizePrimaryTextColor = false),
				(this.customizeSecondaryTextColor = false),
				(this.customizeFooterBackgroundColor = false),
				(this.customizeFooterTextColor = false),
				(this.customizeNavbarTextColor = false),
				(this.customizeNavbarBackgroundColor = false)
			)
		},
		customizeBackgroundColor() {
			return (
				(this.customizePrimaryColor = false),
				(this.customizeSecondaryColor = false),
				(this.customizePrimaryTextColor = false),
				(this.customizeSecondaryTextColor = false),
				(this.customizeFooterBackgroundColor = false),
				(this.customizeFooterTextColor = false),
				(this.customizeNavbarTextColor = false),
				(this.customizeNavbarBackgroundColor = false)
			)
		},
		customizePrimaryTextColor() {
			return (
				(this.customizePrimaryColor = false),
				(this.customizeSecondaryColor = false),
				(this.customizeBackgroundColor = false),
				(this.customizeFooterBackgroundColor = false),
				(this.customizeFooterTextColor = false),
				(this.customizeNavbarTextColor = false),
				(this.customizeNavbarBackgroundColor = false),
				(this.customizeSecondaryTextColor = false)
			)
		},
		customizeSecondaryTextColor() {
			return (
				(this.customizePrimaryColor = false),
				(this.customizeSecondaryColor = false),
				(this.customizeBackgroundColor = false),
				(this.customizeFooterBackgroundColor = false),
				(this.customizeFooterTextColor = false),
				(this.customizeNavbarTextColor = false),
				(this.customizeNavbarBackgroundColor = false),
				(this.customizePrimaryTextColor = false)
			)
		},
		customizeNavbarBackgroundColor() {
			return (
				(this.customizePrimaryColor = false),
				(this.customizeSecondaryColor = false),
				(this.customizeBackgroundColor = false),
				(this.customizePrimaryTextColor = false),
				(this.customizeSecondaryTextColor = false),
				(this.customizeFooterBackgroundColor = false),
				(this.customizeFooterTextColor = false),
				(this.customizeNavbarTextColor = false)
			)
		},
		customizeNavbarTextColor() {
			return (
				(this.customizePrimaryColor = false),
				(this.customizeSecondaryColor = false),
				(this.customizeBackgroundColor = false),
				(this.customizePrimaryTextColor = false),
				(this.customizeSecondaryTextColor = false),
				(this.customizeFooterBackgroundColor = false),
				(this.customizeFooterTextColor = false),
				(this.customizeNavbarBackgroundColor = false)
			)
		},
		customizeFooterBackgroundColor() {
			return (
				(this.customizePrimaryColor = false),
				(this.customizeSecondaryColor = false),
				(this.customizeBackgroundColor = false),
				(this.customizePrimaryTextColor = false),
				(this.customizeSecondaryTextColor = false),
				(this.customizeNavbarTextColor = false),
				(this.customizeFooterTextColor = false),
				(this.customizeNavbarBackgroundColor = false)
			)
		},
		customizeFooterTextColor() {
			return (
				(this.customizePrimaryColor = false),
				(this.customizeSecondaryColor = false),
				(this.customizeBackgroundColor = false),
				(this.customizePrimaryTextColor = false),
				(this.customizeSecondaryTextColor = false),
				(this.customizeNavbarTextColor = false),
				(this.customizeFooterBackgroundColor = false),
				(this.customizeNavbarBackgroundColor = false)
			)
		},
	},
	methods: {
		saveColors() {
			this.ShowColorPallete = false
			this.loading = true
			let payload = {
				primary: this.customizedPrimaryColor,
				secondary: this.customizedSecondaryColor,
				background: this.customizedBackgroundColor,
				primaryText: this.customizedPrimaryTextColor,
				secondaryText: this.customizedSecondaryTextColor,
				navbar: {
					background: this.navbar.background,
					text: this.navbar.text,
				},
				footer: {
					background: this.footer.background,
					text: this.footer.text,
				},
			}
			this.$emit('on-colors-update', payload)
			this.loading = false
		},
	},
}
</script>

<style scoped>
.customized-color-box {
	cursor: grab;
	border: 1px solid #eee;
	height: 50px;
	width: 50%;
}
.customized-color-box:focus {
	border: 1px solid var(--blue) !important;
}
.customized-color-box:hover {
	border: 1px solid var(--blue);
}
.save {
	background-color: var(--blue) !important;
	color: white;
}
</style>
