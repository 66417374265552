var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-0 d-flex flex-row",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[_c('ol',{staticClass:"pl-4"},[_c('li',{staticClass:"sections mb-5"},[_c('h1',{staticClass:"text-h6 mb-3"},[_vm._v("Website")]),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-0 d-flex flex-row mb-3 align-center justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"mr-5 color-title"},[_vm._v("Primary")]),_c('div',{staticClass:"customized-color-box customized-primary",style:({
									backgroundColor: _vm.customizedPrimaryColor,
								}),on:{"click":function($event){;(_vm.customizePrimaryColor = true),
										(_vm.ShowColorPallete = true)}}})]),_c('v-col',{staticClass:"pa-0 d-flex flex-row mb-3 align-center justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"mr-5 color-title"},[_vm._v("Secondary")]),_c('div',{staticClass:"customized-color-box",style:({
									backgroundColor:
										_vm.customizedSecondaryColor,
								}),on:{"click":function($event){;(_vm.customizeSecondaryColor = true),
										(_vm.ShowColorPallete = true)}}})]),_c('v-col',{staticClass:"pa-0 d-flex flex-row align-center mb-3 justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"mr-5 color-title"},[_vm._v("Background")]),_c('div',{staticClass:"customized-color-box",style:({
									backgroundColor:
										_vm.customizedBackgroundColor,
								}),on:{"click":function($event){;(_vm.customizeBackgroundColor = true),
										(_vm.ShowColorPallete = true)}}})]),_c('v-col',{staticClass:"pa-0 d-flex flex-row align-center mb-3 justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"mr-5 color-title"},[_vm._v("Primary Text")]),_c('div',{staticClass:"customized-color-box",style:({
									backgroundColor:
										_vm.customizedPrimaryTextColor,
								}),on:{"click":function($event){;(_vm.customizePrimaryTextColor = true),
										(_vm.ShowColorPallete = true)}}})]),_c('v-col',{staticClass:"pa-0 d-flex flex-row align-center mb-3 justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"mr-5 color-title"},[_vm._v("Secondary Text")]),_c('div',{staticClass:"customized-color-box",style:({
									backgroundColor:
										_vm.customizedSecondaryTextColor,
								}),on:{"click":function($event){;(_vm.customizeSecondaryTextColor = true),
										(_vm.ShowColorPallete = true)}}})])],1)],1),_c('li',{staticClass:"sections mb-5"},[_c('h1',{staticClass:"text-h6"},[_vm._v("Navbar")]),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-0 d-flex flex-row mb-3 align-center justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"mr-5 color-title"},[_vm._v("Background")]),_c('div',{staticClass:"customized-color-box customized-primary",style:({
									backgroundColor: _vm.navbar.background,
								}),on:{"click":function($event){;(_vm.customizeNavbarBackgroundColor = true),
										(_vm.ShowColorPallete = true)}}})]),_c('v-col',{staticClass:"pa-0 d-flex flex-row mb-3 align-center justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"mr-5 color-title"},[_vm._v("Text")]),_c('div',{staticClass:"customized-color-box",style:({
									backgroundColor: _vm.navbar.text,
								}),on:{"click":function($event){;(_vm.customizeNavbarTextColor = true),
										(_vm.ShowColorPallete = true)}}})])],1)],1),_c('li',{staticClass:"sections mb-5"},[_c('h1',{staticClass:"text-h6"},[_vm._v("Footer")]),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-0 d-flex flex-row mb-3 align-center justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"mr-5 color-title"},[_vm._v("Background")]),_c('div',{staticClass:"customized-color-box customized-primary",style:({
									backgroundColor: _vm.footer.background,
								}),on:{"click":function($event){;(_vm.customizeFooterBackgroundColor = true),
										(_vm.ShowColorPallete = true)}}})]),_c('v-col',{staticClass:"pa-0 d-flex flex-row mb-3 align-center justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"mr-5 color-title"},[_vm._v("Text")]),_c('div',{staticClass:"customized-color-box",style:({
									backgroundColor: _vm.footer.text,
								}),on:{"click":function($event){;(_vm.customizeFooterTextColor = true),
										(_vm.ShowColorPallete = true)}}})])],1)],1)])]),(_vm.ShowColorPallete)?_c('v-col',{staticClass:"pa-0 d-flex justify-end",attrs:{"cols":"8"}},[_c('v-color-picker',{staticClass:"ma-2",attrs:{"show-swatches":"","type":"hex"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1):_vm._e()],1),_c('div',{staticClass:"d-flex flex-row"},[_c('v-btn',{staticClass:"save",attrs:{"depressed":"","loading":_vm.loading},on:{"click":function($event){return _vm.saveColors()}}},[_vm._v("save")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }